import { createSlice } from '@reduxjs/toolkit';

import { login, logout, checkAuth } from '../actions/auth';

import { LoadingState } from '../types';


const initialState: LoadingState = {
    loading: true,
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(login.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(logout.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(logout.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(checkAuth.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(checkAuth.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(checkAuth.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export default loadingSlice.reducer;