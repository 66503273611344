import Select from "react-select";
import React, {useEffect, useState} from "react";
import {Filter} from "../../../common/types/Filter";
import {useGetCategoriesQuery} from "../../../services/api/category";
import {PaymentCategory} from "../../../common/types/PaymentCategory";

export function CategorySelector(props: {
    filter: Filter;
    setFilter: Function;
    handleUpdate: Function;
}) {

    const {data: categories, isLoading}: any = useGetCategoriesQuery();
    const [selectedValue, setSelectedValue] = useState({})

    useEffect(() => {
        if (props.filter.categories__id && !isLoading) {
            let selectedCategory = categories.filter((category: PaymentCategory) => category.id == props.filter.categories__id)[0]
            let newValue = {value: props.filter.categories__id, label: selectedCategory.name}
            setSelectedValue(newValue)
        } else {
            setSelectedValue([null])
        }

    }, [props.filter, isLoading])


    return (
        <>
            <Select
                isClearable={true}
                placeholder={"Category"}
                isLoading={isLoading}
                value={selectedValue}
                menuPosition="fixed"
                onChange={(e) => props.handleUpdate(e, "categories__id")}
                options={categories?.map(
                    (category: PaymentCategory) => {
                        return {value: category.id, label: category.name}
                    }
                )
                }/>
        </>
    );


}
