import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { NewButton, Table, Title } from "../../components";
import { ColumnFilter } from "../../components/Table/Filter";
import { useGetInvoicesQuery } from "../../services/api/invoice";

import { Invoice } from "../../common/types/Invoice";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<Invoice>(); // Initialize columnHelper for Invoice type

const columns = [
  columnHelper.accessor("name", {
    id: "name",
    header: "Filename",
    cell: (info) => info.getValue(), // Optionally, define how cell data is retrieved or displayed
    filterFn: "equals", // Assuming you're using a built-in filter function or define how to filter
    // If ColumnFilter is a component for a custom UI filter, you might need additional setup
  }),
  columnHelper.accessor(
    (data: Invoice) =>
      data.payments?.map((payment) => payment?.code).join(", "),
    {
      id: "payments", // Necessary when using accessorFn to uniquely identify the column
      header: "Payments",
      cell: (info) => info.getValue(), // Optionally, define how cell data is retrieved or displayed
      filterFn: "equals", // Assuming you're using a built-in filter function or define how to filter
      // If ColumnFilter is a component for a custom UI filter, you might need additional setup
    }
  ),
];
export function Invoices() {
  let [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(
    queryPage !== null ? parseInt(queryPage) : 1
  );

  const { data } = useGetInvoicesQuery(page);

  const navigate = useNavigate();

  const onLineClick = (row: Invoice) => {
    navigate(`/invoices/${row.id}`);
  };

  const newInvoice = () => {
    navigate("/invoices/new");
  };

  return (
    <>
      <Title>Invoices</Title>

      <Table
        data={data || []}
        count={data?.length || 0}
        page={page}
        setPage={setPage}
        onLineClick={onLineClick}
        columns={columns}
      />

      <div className="flex align-center justify-center mt-5">
        <NewButton onClick={newInvoice}>New Invoice</NewButton>
      </div>
    </>
  );
}
