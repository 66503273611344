import {useGetGroupsQuery} from "../../../services/api/group";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import {Filter} from "../../../common/types/Filter";


export function GroupSelector(props: {
    filter: Filter;
    setFilter: Function;
    handleUpdate: Function;
}) {

    const {data: groups} = useGetGroupsQuery();
    const [selectedValue, setSelectedValue] = useState({})

    useEffect(() => {
        if (props.filter.group) {
            let newValue = {value: props.filter.group, label: props.filter.group}
            setSelectedValue(newValue)
        } else {
            setSelectedValue([null])
        }

    }, [props.filter])


    return (
        <>
            <Select
                isClearable={true}
                placeholder={"Mep group"}
                value={selectedValue}
                menuPosition="fixed"
                onChange={(e) => props.handleUpdate(e, "group")}
                options={groups?.map(
                    (group) => {
                        return {value: group.name, label: group.name}
                    }
                )
                }/>
        </>
    );


}
