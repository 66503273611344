import {GroupSelector} from "./selectors/GroupSelector";
import {SingleValue} from "react-select";
import CodeSelector from "./selectors/CodeSelector";
import {MepSelector} from "./selectors/MepSelector";
import {Filter} from "../../common/types/Filter";
import {PayerSelector} from "./selectors/PayerSelector";
import {CategorySelector} from "./selectors/CategorySelector";
import "./Filter.css"
import React from "react";
import {DateSelector} from "./selectors/DateSelector";
import {useGetPaymentCodesQuery} from "../../services/api/paymentCodes";


export function PaymentFilter(props: { filter: Filter; setFilter: Function; }) {
    let handleUpdate = (event: SingleValue<{ value: string; label: string; }>, option: string) => {
        if (event && event.value) {
            let copy: Filter = {...props.filter}
            // @ts-ignore
            copy[option] = event.value
            props.setFilter(copy)
        } else {
            // @ts-ignore
            if (props.filter[option]) {
                let copy: Filter = {...props.filter};
                // @ts-ignore
                delete copy[option]
                props.setFilter(copy)
            }

        }
    }
    return (
        <div className="container columns-1 sm:columns-2 md:columns-5 gap-1 mx-auto Filter">
            <GroupSelector filter={props.filter}
                           setFilter={props.setFilter}
                           handleUpdate={handleUpdate}/>
            <MepSelector filter={props.filter}
                         setFilter={props.setFilter}
                         handleUpdate={handleUpdate}/>
            <PayerSelector filter={props.filter}
                           setFilter={props.setFilter}
                           handleUpdate={handleUpdate}/>
            <CodeSelector filter={props.filter}
                          setFilter={props.setFilter}
                          handleUpdate={handleUpdate}
                          useQuery={useGetPaymentCodesQuery}/>
            <CategorySelector filter={props.filter}
                              setFilter={props.setFilter}
                              handleUpdate={handleUpdate}/>
            <DateSelector filter={props.filter}
                          setFilter={props.setFilter}
                          handleUpdate={handleUpdate}/>

        </div>
    )

}

export default PaymentFilter;