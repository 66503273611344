import { api } from '..';
import { PaymentCategory } from '../../../common/types/PaymentCategory';
import { PaginatedResponse } from '../types';

export const categoryAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query<PaymentCategory[], void>({
            query: () => `${process.env.REACT_APP_PAYMENT_CATEGORY_URL}`,
            transformResponse: (response: PaginatedResponse<PaymentCategory>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ name }) => ({ type: 'Categories' as const, id: name })),
                        { type: 'Categories', id: 'LIST' },
                    ]
                    : [{ type: 'Categories', id: 'LIST' }],
        }),
    }),
});

export const { useGetCategoriesQuery } = categoryAPI;