import { api } from '..';
import { Balance, MepBalance } from '../../../common/types/Balance';
import { PaginatedResponse } from '../types';

export const balanceAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getBalances: builder.query<MepBalance[], {
            page?: number,
            mep_group: number,
            start_year?: number,
            end_year?: number,
        }>({
            query: (data) => ({
                url: '/balances/',
                params: data
            }),
            transformResponse: (response: PaginatedResponse<Balance>, meta, arg) => response.results[0].mep_balances,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Balances' as const, id })),
                        { type: 'Balances', id: 'LIST' },
                    ]
                    : [{ type: 'Balances', id: 'LIST' }],
        }),
    }),
});

export const { useGetBalancesQuery } = balanceAPI;