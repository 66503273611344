import {api} from ".."

export const paymentCodesAPI = api.injectEndpoints({
    endpoints: (builder) => {
        return ({
            getPaymentCodes: builder.query({
                query: (searchValue) => {
                    let params = new URLSearchParams()
                    if (searchValue) {
                        params.append('search', searchValue)
                    }
                    return process.env.REACT_APP_PAYMENT_CODES_URL + `?${params.toString()}`
                },
            }),
        });
    },
});

export const {useGetPaymentCodesQuery,} = paymentCodesAPI;