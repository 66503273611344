import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { Layout, RequireAuth } from "../components";
import {
  Login,
  ArchivePayments,
  ArchivePayment,
  Payments,
  Payment,
  EditPayment,
  NewPayment,
  Invoices,
  EditInvoice,
  NewInvoice,
  AccountOwners,
  EditAccountOwner,
  NewAccountOwner,
  NewBankAccount,
  EditBankAccount,
  Balances,
  MergeAccountOwners
} from "../pages";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<ArchivePayments />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path='/archived/:id' element={<ArchivePayment />} />

        <Route element={<RequireAuth />}>

          <Route path='/payments' element={<Payments />} />
          <Route path='/payments/:id' element={<Payment />} />
          <Route path='/payments/:id/edit' element={<EditPayment />} />
          <Route path='/payments/new' element={<NewPayment />} />

          <Route path='/invoices' element={<Invoices />} />
          <Route path='/invoices/:id' element={<EditInvoice />} />
          <Route path='/invoices/new' element={<NewInvoice />} />

          <Route path='/account_owners' element={<AccountOwners />} />
          <Route path='/account_owners/:id' element={<EditAccountOwner />} />
          <Route path='/account_owners/new' element={<NewAccountOwner />} />
          <Route path='/account_owners/merge' element={<MergeAccountOwners />} />

          <Route path='/bank_accounts/:id' element={<EditBankAccount />} />
          <Route path='/bank_accounts/new' element={<NewBankAccount />} />

          <Route path='/balances' element={<Balances />} />
        </Route>
      </Route>
    </Routes>
  );
}
