import { login, logout, checkAuth } from "../actions/auth";
import { setAuthToken, saveAuthStorage } from "../../../utils/auth";
import { Action, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AuthResponse } from "../../auth/types";

interface ActionWithPayload extends Action {
  payload?: AuthResponse;
}

export const authTokenMiddleware: Middleware =
  (api: MiddlewareAPI) => (next: Dispatch) => (action: ActionWithPayload) => {
    switch (action.type) {
      case login.fulfilled.type:
        setAuthToken(action.payload?.token);
        saveAuthStorage(action.payload);
        break;

      case logout.fulfilled.type:
        setAuthToken();
        saveAuthStorage();
        break;
    }

    return next(action);
  };
