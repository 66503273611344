import React, { useMemo, useState } from "react";
import {
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  MouseSensor,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";

import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import DraggableTableHeader from "./DraggableTableHeader";
import DragAlongCell from "./DragAlongCell";

function Table(props: {
  data: any[];
  page: number;
  count: number;
  columns: any[];
  hasNext: boolean;
  hasPrev: boolean;
  setPage: Function;
  onLineClick: Function;
}) {
  const nextPage = () => props.setPage((page) => page + 1);
  const prevPage = () => props.setPage((page) => (page > 1 ? page - 1 : page));
  const pages = Math.floor(props.count / 50) + 1;
  const memoizedData = useMemo(() => props.data, [props.data]);
  const [columnOrder, setColumnOrder] = React.useState<string[]>(() =>
    props.columns.map((c) => c.id!)
  );
  const tableInstance = useReactTable({
    columns: props.columns,
    data: memoizedData,
    state: { columnOrder },
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    console.log(active, over);
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        const x = arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
        console.log(x, columnOrder);
        return x;
      });
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
    >
      <div className="relative content-center flex justify-center">
        <div className="shadow-md rounded-lg overflow-x-scroll w-fit min-w-[50%]">
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <SortableContext
                items={props.columns}
                strategy={horizontalListSortingStrategy}
              >
                <thead className="text-xs text-gray-700 uppercase bg-gray-300">
                  {tableInstance.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <DraggableTableHeader header={header} key={header.id} />
                      ))}
                    </tr>
                  ))}
                </thead>
              </SortableContext>
              <tbody>
                {tableInstance.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <SortableContext
                        key={cell.id}
                        items={columnOrder}
                        strategy={horizontalListSortingStrategy}
                      >
                        <DragAlongCell cell={cell} key={cell.id} />
                      </SortableContext>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full p-2 flex justify-between">
            <div className="h-full py-2">
              <label>
                Page {props.page} of {pages}
              </label>
            </div>

            <div className="flex-end">
              <button
                className="inline-flex items-center py-2 px-4 text-sm font-medium bg-white rounded-lg border border-gray-300 hover:text-blue-700"
                onClick={() => prevPage()}
                disabled={!props.hasPrev}
              >
                Previous
              </button>
              <select
                value={props.page}
                className="inline-flex items-center py-2 px-4 text-sm font-medium bg-white rounded-lg border border-gray-300 hover:text-blue-700 ml-3"
                onChange={(x) => props.setPage(parseInt(x.target.value))}
              >
                {Array.from({ length: pages }, (_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>{" "}
              <button
                className="inline-flex items-center py-2 px-4 ml-3 text-sm font-medium bg-white rounded-lg border border-gray-300 hover:text-blue-700"
                onClick={() => nextPage()}
                disabled={!props.hasNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </DndContext>
  );
}

export default Table;
