import { createSlice } from '@reduxjs/toolkit';

import { getAuthLocalStorage } from '../../../utils/auth';
import { login, logout, checkAuth } from '../actions/auth';

import { AuthState } from '../types';

const storeValues = getAuthLocalStorage();

const initialState: AuthState = {
    user: storeValues?.user,
    token: storeValues?.token,
    isLoggedIn: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isLoggedIn = true;
        });

        builder.addCase(logout.fulfilled, (state, action) => {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
        });

        builder.addCase(checkAuth.fulfilled, (state, action) => {
            state.isLoggedIn = true;
        });
        builder.addCase(checkAuth.rejected, (state, action) => {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
        });
    },
});

export default authSlice.reducer;