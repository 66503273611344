import React from 'react';
import { Form, Subtitle } from '../../../components';
import { Field } from '../../../components/Form/Form';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { useGetInvoiceQuery, useUpdateInvoiceMutation } from '../../../services/api/invoice';

import { useAppDispatch } from '../../../services/redux/hooks';
import { setNotification } from "../../../services/redux/actions/notification";

import { Invoice } from '../../../common/types/Invoice';

const defaultFields: Field[] = [
    {
        name: 'file',
        type: 'file',
        label: 'File',
        accessor: (data: Invoice) => data.file,
        required: true,
    },
    {
        name: 'name',
        type: 'text',
        label: 'Name',
        required: true,
    },
];



export function EditInvoice() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: invoice, isLoading } = useGetInvoiceQuery(id!);

    const [editInvoice, { error, isLoading: isEditLoading }] = useUpdateInvoiceMutation();

    const onSubmit = async (data: any) => {
        const { file, name } = data;

        const body = new FormData();
        body.append('file', file);
        body.append('name', name);

        if (id) {
            const resp = await editInvoice({ id, body });

            if (!resp?.["error"]) {
                dispatch(setNotification({
                    type: 'success',
                    message: 'Invoice updated'
                }));

                navigate("/invoices");
            }
        }
    }

    return (
        <>
            {invoice?.payments && invoice?.payments.length > 0 && (
                <div className="flex justify-center error mb-8">
                    <div className="p-4 mb-4 text-base text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <span className="font-medium">WARNING!</span> This invoices has payments.
                        <div className="mt-2">
                            {invoice?.payments?.map(payment => (
                                <Link key={payment?.id} className="text-blue-600 mb-2 mr-2" to={`/payments/${payment?.id}`}>{payment?.code}</Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <Subtitle>Edit Invoice {invoice?.name}</Subtitle>
            <div className="flex justify-center  mb-8">
                <p className='w-[90%] md:w-[50%]'>
                    File: <a className="text-blue-600" href={invoice?.file} target="_blank" rel="noreferrer">{invoice?.file}</a>
                </p>
            </div>
            <div className="flex justify-center">
                <Form
                    fields={defaultFields}
                    data={invoice}
                    loading={isLoading || isEditLoading}
                    errors={error?.["data"]}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
}