import { api } from '..';
import { MepGroup } from '../../../common/types/MepGroup';
import { PaginatedResponse } from '../types';

export const groupAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getGroups: builder.query<MepGroup[], void>({
            query: () => `${process.env.REACT_APP_MEP_GROUPS_URL}`,
            transformResponse: (response: PaginatedResponse<MepGroup>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ code }) => ({ type: 'MepGroups' as const, id: code })),
                        { type: 'MepGroups', id: 'LIST' },
                    ]
                    : [{ type: 'MepGroups', id: 'LIST' }],
        }),
    }),
});

export const { useGetGroupsQuery } = groupAPI;