import { api } from '..';
import { BankAccount } from '../../../common/types/BankAccount';
import { PaginatedResponse } from '../types';

export const bankAccountAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getBankAccounts: builder.query<BankAccount[], number | void>({
            query: (page) => page ? `/bank_accounts/?page=${page}` : '/bank_accounts/',
            transformResponse: (response: PaginatedResponse<BankAccount>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'BankAccounts' as const, id })),
                        { type: 'BankAccounts', id: 'LIST' },
                    ]
                    : [{ type: 'BankAccounts', id: 'LIST' }],
        }),
        addBankAccount: builder.mutation<BankAccount, Partial<BankAccount>>({
            query: (body) => ({
                url: `/bank_accounts/`,
                method: 'POST',
                body
            }),
            invalidatesTags: () => [{ type: 'BankAccounts', id: 'LIST' }]
        }),
        getBankAccount: builder.query<BankAccount, string>({
            query: (id) => `/bank_accounts/${id}/`,
            providesTags: (result, error, id) => [{ type: 'BankAccounts', id: id }],
        }),
        updateBankAccount: builder.mutation<BankAccount, Partial<BankAccount>>({
            query: (data) => {
                const { id, ...body } = data;

                return {
                    url: `/bank_accounts/${id}/`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'BankAccounts', id }]
        }),
        deleteBankAccount: builder.mutation<{ success: boolean }, string>({
            query: (id) => ({ url: `/bank_accounts/${id}/`, method: 'DELETE' }),
            invalidatesTags: () => [{ type: 'BankAccounts', id: 'LIST' }],
        }),
    }),
});

export const { useGetBankAccountsQuery, useGetBankAccountQuery, useAddBankAccountMutation, useUpdateBankAccountMutation, useDeleteBankAccountMutation } = bankAccountAPI;