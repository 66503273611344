import { api } from '..';
import { PaymentState } from '../../../common/types/PaymentState';
import { PaginatedResponse } from '../types';

export const paymentStateAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentStates: builder.query<PaymentState[], void>({
            query: () => `/payment_states/`,
            transformResponse: (response: PaginatedResponse<PaymentState>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ name }) => ({ type: 'PaymentState' as const, id: name })),
                        { type: 'PaymentState', id: 'LIST' },
                    ]
                    : [{ type: 'PaymentState', id: 'LIST' }],
        }),
    }),
});

export const { useGetPaymentStatesQuery } = paymentStateAPI;