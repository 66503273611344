import { fetchAPI } from "../api";
import { AuthResponse, AuthData } from "./types";

import { AxiosResponse } from "axios";

const login = async (token: string): Promise<AuthResponse> => {
  return { token };
};

const logout = async (): Promise<{ success: boolean; message: string }> => {
  return fetchAPI.get("/logout/").then((res) => res.data);
};

const checkAuth = async (): Promise<AxiosResponse> => {
  return fetchAPI.get("/auth/").then((res) => res.data);
};

const authAPI = { login, logout, checkAuth };
export default authAPI;
