import {api} from '..';
import {Payment} from '../../../common/types/Payment';


export const ArchivePaymentAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getArchivePayments: builder.query({
            query: (args) => {
                let params = new URLSearchParams(args.filter);
                if(args.page > 1) {
                    params.append('page', args.page)
                }
                return process.env.REACT_APP_ARCHIVE_LIST_URL + `?${params.toString()}`;
            },
        }),
        getArchivePayment: builder.query<Payment, string>({
            query: (code) => process.env.REACT_APP_ARCHIVE_DETAIL_URL + `${code}/`,
            providesTags: (result, error, code) => [{type: 'ArchivePayments', id: code}],
        }),
    }),
});

export const {useGetArchivePaymentsQuery, useGetArchivePaymentQuery} = ArchivePaymentAPI;