import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Invoice } from "./Invoice";
import { Presenter } from "../../../components";
import { useAppDispatch } from "../../../services/redux/hooks";
import { useGetPaymentQuery, useDeletePaymentMutation } from '../../../services/api/payment';
import { useConfirmationModal } from '../../../services/context/modals/ConfirmModal';

import { Row } from '../../../components/Presenter/Presenter';
import { Payment as PaymentI } from "../../../common/types/Payment";
import { setNotification } from "../../../services/redux/actions/notification";


const rows: Row<PaymentI>[] = [
    {
        name: 'From',
        accessor: data => data.payer.name,
    },
    {
        name: 'To',
        accessor: data => data.account_owner.name,
    },
    {
        name: 'Final receiver',
        accessor: data => data.final_receivers?.map(receiver => receiver.name).join(', '),
    },
    {
        name: 'Category',
        accessor: data => data.categories.map(category => category.name).join(', '),
    },
    {
        name: 'Amount',
        accessor: data => data.amount,
    },
    {
        name: 'Real amount',
        accessor: data => data.real_amount,
    },
    {
        name: 'Date',
        accessor: data => data.date,
    },
    {
        name: 'Status',
        accessor: data => data.status.name,
    },
    {
        name: 'Notes',
        accessor: data => data.notes,
    },
    {
        name: 'Invoice',
        accessor: data => data.invoice,
        Component: Invoice,
    },
    {
        name: 'Description',
        accessor: data => data.description,
    }
];



export function Payment() {
    const { id } = useParams();
    const { data } = useGetPaymentQuery(id!);
    const [deletePayment] = useDeletePaymentMutation();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const { setConfirmMessage } = useConfirmationModal();

    const editPayment = () => {
        navigate(`/payments/${id}/edit`);
    }

    const confirmDelete = () => {
        setConfirmMessage({
            message: "Are you sure you want to delete this payment?",
            onConfirm: () => onDelete,
            onCancel: () => onCancel
        });
    }

    const onCancel = () => {
    }

    const onDelete = async () => {
        const resp = await deletePayment(id!);

        if (!resp["error"]) {
            dispatch(setNotification({
                message: "Payment deleted",
                type: "success"
            }))
            navigate('/payments');
        }
    }

    return (
        <>
            <h2 className="text-center text-lg mb-5 font-semibold text-gray-600">Payment</h2>

            <div className="buttons-container flex flex-row justify-center pa w-full gap-3 m-auto pb-5">
                <button
                    className="text-white align-middle bg-blue-600 block hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                    onClick={() => editPayment()}
                >
                    Edit
                </button>
                <button
                    className="text-white align-middle bg-red-600 block hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                    onClick={() => confirmDelete()}
                >
                    Delete
                </button>
            </div>

            <Presenter
                data={data}
                rows={rows}
            />
        </>
    );
}