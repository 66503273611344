import { api } from '..';
import { AccountOwner } from '../../../common/types/AccountOwner';
import { PaginatedResponse } from '../types';

export const accountOwnerAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getAccountOwners: builder.query<AccountOwner[], number | void>({
            query: (page) => page ? `/account_owners/?page=${page}` : '/account_owners/',
            transformResponse: (response: PaginatedResponse<AccountOwner>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'AccountOwners' as const, id })),
                        { type: 'AccountOwners', id: 'LIST' },
                    ]
                    : [{ type: 'AccountOwners', id: 'LIST' }],
        }),
        addAccountOwner: builder.mutation<AccountOwner, Partial<AccountOwner>>({
            query: (body) => ({
                url: `/account_owners/`,
                method: 'POST',
                body
            }),
            invalidatesTags: () => [{ type: 'AccountOwners', id: 'LIST' }]
        }),
        mergeAccountOwner: builder.mutation<AccountOwner, { account_owners: number[] }>({
            query: (body) => ({
                url: `/account_owners/merge/`,
                method: 'POST',
                body
            }),
            invalidatesTags: () => [{ type: 'AccountOwners', id: 'LIST' }]
        }),
        getAccountOwner: builder.query<AccountOwner, string>({
            query: (id) => `/account_owners/${id}/`,
            providesTags: (result, error, id) => [{ type: 'AccountOwners', id: id }],
        }),
        updateAccountOwner: builder.mutation<AccountOwner, Partial<AccountOwner>>({
            query: (data) => {
                const { id, ...body } = data;

                return {
                    url: `/account_owners/${id}/`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'AccountOwners', id }]
        }),
        deleteAccountOwner: builder.mutation<{ success: boolean }, string>({
            query: (id) => ({ url: `/account_owners/${id}/`, method: 'DELETE' }),
            invalidatesTags: () => [{ type: 'AccountOwners', id: 'LIST' }],
        }),
    }),
});

export const { useGetAccountOwnersQuery, useGetAccountOwnerQuery, useAddAccountOwnerMutation, useMergeAccountOwnerMutation, useUpdateAccountOwnerMutation, useDeleteAccountOwnerMutation } = accountOwnerAPI;