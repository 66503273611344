import {api} from '..';
import {Payment} from '../../../common/types/Payment';

export const paymentAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getPayments: builder.query({
            query: (args) => {
                let params = new URLSearchParams(args.filter);
                if (args.page > 1) {
                    params.append('page', args.page)
                }
                return process.env.REACT_APP_PAYMENTS_LIST_URL + `?${params.toString()}`;
            },
        }),
        addPayment: builder.mutation<Payment, Partial<Payment>>({
            query: (body) => ({
                url: process.env.REACT_APP_PAYMENTS_LIST_URL,
                method: 'POST',
                body
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Payments', id}]
        }),
        getPayment: builder.query<Payment, string>({
            query: (id) => `${process.env.REACT_APP_PAYMENTS_LIST_URL}${id}/`,
            providesTags: (result, error, id) => [{type: 'Payments', id}],
        }),
        updatePayment: builder.mutation<Payment, Partial<Payment>>({
            query: (data) => {
                const {id, ...body} = data;

                return {
                    url: `/payments/${id}/`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Payments', id}]
        }),
        deletePayment: builder.mutation<{ success: boolean, id: string }, string>({
            query: (id) => ({url: `/payments/${id}/`, method: 'DELETE'}),
            invalidatesTags: (result, error, id) => [{type: 'Payments', id: 'LIST'}],
        }),
    }),
});

export const {
    useGetPaymentsQuery,
    useAddPaymentMutation,
    useGetPaymentQuery,
    useUpdatePaymentMutation,
    useDeletePaymentMutation
} = paymentAPI;