export const currencies = [
    {
        value: "CZK",
        label: "Kč"
    },
    {
        value: "EUR",
        label: "€"
    },
    {
        value: "USD",
        label: "$"
    },
    {
        value: "GBP",
        label: "£"
    }
];
