import React from "react";
import { Form, Subtitle } from "../../../components";
import { Field } from "../../../components/Form/Form";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  useGetAccountOwnerQuery,
  useUpdateAccountOwnerMutation,
} from "../../../services/api/accountOwner";

import { useAppDispatch } from "../../../services/redux/hooks";
import { setNotification } from "../../../services/redux/actions/notification";

import { BankAccounts } from "../../BankAccounts/BankAccounts";

const defaultFields: Field[] = [
  {
    name: "name",
    type: "text",
    label: "Name",
    required: true,
  },
];

export function EditAccountOwner() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: accountOwner, isLoading } = useGetAccountOwnerQuery(id!);

  const [editAccountOwner, { error, isLoading: isEditLoading }] =
    useUpdateAccountOwnerMutation();

  const onSubmit = async (data: any) => {
    data = { ...data, id };

    const resp = await editAccountOwner(data);

    if (!resp?.["error"]) {
      dispatch(
        setNotification({
          type: "success",
          message: "AccountOwner updated",
        })
      );

      navigate("/account_owners");
    }
  };

  return (
    <>
      {accountOwner?.payments && accountOwner?.payments.length > 0 && (
        <div className="flex justify-center items-center error mb-8 ">
          <div
            className="max-w-xl p-4 mb-4 text-base text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <span className="font-medium">WARNING!</span> This Account Owner has
            payments.
            <div className="mt-2 break-words max-w-xl mx-auto">
              {accountOwner?.payments?.map((payment) => (
                <Link
                  key={payment?.id}
                  className="text-blue-600 mb-2 mr-2"
                  to={`/payments/${payment?.id}`}
                >
                  {payment?.code}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      <Subtitle>Edit AccountOwner {accountOwner?.name}</Subtitle>
      <div className="flex justify-center mb-8">
        <Form
          fields={defaultFields}
          data={accountOwner}
          loading={isLoading || isEditLoading}
          errors={error?.["data"]}
          onSubmit={onSubmit}
        />
      </div>

      <BankAccounts accountOwner={accountOwner} />
    </>
  );
}
