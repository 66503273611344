import React, { useMemo } from 'react';
import { Form, Subtitle } from '../../../components';
import { Field } from '../../../components/Form/Form';

import { useNavigate, useParams } from 'react-router-dom';

import { useGetPaymentQuery, useUpdatePaymentMutation } from '../../../services/api/payment';
import { useGetGroupsQuery } from '../../../services/api/group';
import { useGetMepsQuery } from '../../../services/api/mep';
import { useGetCategoriesQuery } from '../../../services/api/category';
import { useGetAccountOwnersQuery } from '../../../services/api/accountOwner';
import { useGetInvoicesQuery } from '../../../services/api/invoice';
import { useGetPaymentStatesQuery } from '../../../services/api/paymentState';
import { useGetBankAccountsQuery } from '../../../services/api/bankAccount';

import { useAppDispatch } from '../../../services/redux/hooks';
import { setNotification } from "../../../services/redux/actions/notification";

import { currencies } from '../../../services/api/constants';
import { PaymentCategory } from '../../../common/types/PaymentCategory';
import { BankAccount } from '../../../common/types/BankAccount';
import { AccountOwner } from '../../../common/types/AccountOwner';
import { Invoice } from '../../../common/types/Invoice';
import { PaymentState } from '../../../common/types/PaymentState';
import { MepGroup } from '../../../common/types/MepGroup';
import { Mep } from '../../../common/types/Mep';


const defaultFields: Field[] = [
    {
        name: 'group',
        type: 'select',
        accessor: (data: MepGroup) => data.id,
        label: 'MepGroup',
    },
    {
        name: 'mep',
        type: 'select',
        accessor: (data: Mep) => data.id,
        label: 'Mep',
    },
    {
        name: 'categories',
        type: 'select',
        accessor: (data: PaymentCategory[]) => data.map(category => category.id),
        multiple: true,
        label: 'Categories',
        required: true,
    },
    {
        name: 'currency',
        type: 'select',
        label: 'Currency',
        required: true,
    },
    {
        name: 'amount',
        type: 'number',
        label: 'Amount',
        required: true,
    },
    {
        name: 'real_amount',
        type: 'number',
        label: 'Real Amount',
        required: true,
    },
    {
        name: 'description',
        type: 'text',
        label: 'Description',
        required: true,
    },
    {
        name: 'date',
        type: 'date',
        label: 'Date',
        required: true,
    },
    {
        type: 'select',
        name: 'account_owner',
        accessor: (data: AccountOwner) => data.id,
        label: 'Account Owner',
        required: true
    },
    {
        type: 'select',
        name: 'payer',
        accessor: (data: AccountOwner) => data.id,
        label: 'Payer',
        required: true
    },
    {
        type: 'select',
        name: 'bank_account',
        accessor: (data: BankAccount) => data.id,
        label: 'Bank account',
    },
    {
        type: 'select',
        name: 'final_receivers',
        accessor: (data: AccountOwner[]) => data.map(final_receiver => final_receiver.id),
        multiple: true,
        label: 'Final Receivers',
    },
    {
        type: 'text',
        name: 'notes',
        label: 'Notes',
        required: false
    },
    {
        type: 'select',
        name: 'invoice',
        accessor: (data: Invoice[]) => data.map(invoice => invoice.id),
        multiple: true,
        label: 'Invoice',
    },
    {
        type: 'select',
        name: 'status',
        accessor: (data: PaymentState) => data.id,
        label: 'Status',
    }
];



export function EditPayment() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data, isLoading } = useGetPaymentQuery(id!);
    const { data: groups } = useGetGroupsQuery();
    const { data: meps } = useGetMepsQuery();
    const { data: categories } = useGetCategoriesQuery();
    const { data: accountOwners } = useGetAccountOwnersQuery();
    const { data: invoices } = useGetInvoicesQuery();
    const { data: status } = useGetPaymentStatesQuery();
    const { data: bankAccounts } = useGetBankAccountsQuery();

    const [editPayment, { error, isLoading: isEditLoading }] = useUpdatePaymentMutation();

    const selectFrom = useMemo(() => {
        return {
            group: groups?.map(group => ({
                value: group.id,
                label: group.name
            })),
            mep: meps?.map(mep => ({
                value: mep.id,
                label: mep.name
            })),
            bank_account: bankAccounts?.map(bank => ({
                value: bank.id,
                label: bank.identifier
            })),
            currency: currencies,
            categories: categories?.map(category => ({
                value: category.id,
                label: category.name
            })),
            account_owner: accountOwners?.map(accountOwner => ({
                value: accountOwner.id,
                label: accountOwner.name
            })),
            payer: meps?.map(mep => ({
                value: mep.id,
                label: mep.name
            })),
            final_receivers: accountOwners?.map(accountOwner => ({
                value: accountOwner.id,
                label: accountOwner.name
            })),
            invoice: invoices?.map(invoice => ({
                value: invoice.id,
                label: invoice.name
            })),
            status: status?.map(status => ({
                value: status.id,
                label: status.name
            }))
        }
    }, [groups, meps, categories, accountOwners, invoices, status, bankAccounts]);

    const onSubmit = async (data: any) => {
        data = { id, ...data };
        const resp = await editPayment(data);

        if (!resp?.["error"]) {
            dispatch(setNotification({
                type: 'success',
                message: 'Payment updated'
            }));

            navigate("/payments");
        }
    }

    return (
        <>
            <Subtitle>Edit Payment {data?.id}</Subtitle>
            <div className="flex justify-center">
                <Form
                    fields={defaultFields}
                    data={data}
                    loading={isLoading || isEditLoading}
                    errors={error?.["data"]}
                    selectFrom={selectFrom}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
}