import React, {useEffect, useState} from "react";
import {Filter} from "../../../common/types/Filter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function DateSelector(props: {
    filter: Filter;
    setFilter: Function;
    handleUpdate: Function;
}) {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const updateStart = (date: Date | null) => {
        setStartDate(date);
        props.handleUpdate({value: date ? date.toISOString().substring(0, 10) : null}, 'date_after')
    }
    const updateEnd = (date: Date | null) => {
        setEndDate(date);
        props.handleUpdate({value: date ? date.toISOString().substring(0, 10) : null}, 'date_before')
    }

    useEffect(() => {
        if (props.filter.date_after) {
            setStartDate(new Date(props.filter.date_after))
        }
        if (props.filter.date_before) {
            setEndDate(new Date(props.filter.date_before))
        }

    }, [props.filter])
    return (
        <>
            <DatePicker
                isClearable={true}
                dateFormat="yyyy-MM-dd"
                placeholderText={"Date after"}
                selected={startDate}
                onChange={(date) => updateStart(date)}
                minDate={new Date('2019-04')}
                calendarStartDay={1}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
            />
            <DatePicker
                isClearable={true}
                dateFormat="yyyy-MM-dd"
                placeholderText={"Date before"}
                selected={endDate}
                onChange={(date) => updateEnd(date)}
                minDate={new Date('2019-04')}
                calendarStartDay={1}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
            />
        </>
    )

}