import axios from "axios";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getTokenLocalStorage } from "../../utils/auth";
import { Store } from "../redux/types";

export const fetchAPI = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 2500,
});

// fetchAPI.defaults.headers.common['Authorization'] = `Token ${getTokenLocalStorage()}`;

const fetchBase = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_SERVER,
  prepareHeaders: (headers, { getState }) => {
    const store = getState() as Store;
    const token = store.auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    } else {
      // get from local storage
      const token = getTokenLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    }

    return headers;
  },
});

const fetchSearch = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_SERVER,
  prepareHeaders: (headers, { getState }) => {
    const store = getState() as Store;
    const token = store.auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const api = createApi({
  baseQuery: fetchBase,
  tagTypes: [
    "PaymentState",
    "Payments",
    "Meps",
    "Invoices",
    "MepGroups",
    "Categories",
    "BankAccounts",
    "ArchivePayments",
    "ArchiveCodes",
    "AccountOwners",
    "Balances",
  ],
  endpoints: () => ({}),
});
