import React, {useEffect, useState} from "react";
import {Filter} from "../../../common/types/Filter";
import {useGetMepsQuery} from "../../../services/api/mep";
import Select from "react-select";

export function PayerSelector(props: {
    filter: Filter; setFilter: Function; handleUpdate: Function;
}) {
    const {data: payers, isLoading} = useGetMepsQuery()
    const [selectedValue, setSelectedValue] = useState({})

    useEffect(() => {
        if (props.filter.payer && !isLoading) {
            let newValue = {value: props.filter.payer, label: props.filter.payer}
            setSelectedValue(newValue)
        } else {
            setSelectedValue([null])
        }

    }, [props.filter, isLoading])
    return (<>
            <Select
                isClearable={true}
                placeholder={"Payer"}
                value={selectedValue}
                menuPosition="fixed"
                onChange={(e) => {
                    props.handleUpdate(e, "payer")
                }}
                options={payers?.map((mep) => {
                    return {value: mep.name, label: mep.name}
                })}/>
        </>)
}
