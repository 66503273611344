import React from "react";
import {BrowserRouter} from "react-router-dom";

import {Router} from "./routes";
import {Navbar, Spinner, Notification} from "./components";

import './App.css';


function App() {
    return (
        <>
                <BrowserRouter>
                    <Spinner/>
                    <Navbar/>
                    <Notification/>

                    <Router/>
                </BrowserRouter>
        </>
    );
}

export default App;
