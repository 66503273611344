import { api } from '..';
import { Invoice } from '../../../common/types/Invoice';
import { PaginatedResponse } from '../types';

export const invoiceAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getInvoices: builder.query<Invoice[], number | void>({
            query: (page) => page ? `/invoices/?page=${page}` : '/invoices/',
            transformResponse: (response: PaginatedResponse<Invoice>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Invoices' as const, id })),
                        { type: 'Invoices', id: 'LIST' },
                    ]
                    : [{ type: 'Invoices', id: 'LIST' }],
        }),
        addInvoice: builder.mutation<Invoice, FormData>({
            query: (body) => ({
                url: `/invoices/`,
                method: 'POST',
                body
            }),
            invalidatesTags: () => [{ type: 'Invoices', id: 'LIST' }]
        }),
        getInvoice: builder.query<Invoice, string>({
            query: (id) => `/invoices/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Invoices', id: id }],
        }),
        updateInvoice: builder.mutation<Invoice, { body: FormData, id: string }>({
            query: (data) => {
                const { id, body } = data;
                console.log(body);

                return {
                    url: `/invoices/${id}/`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Invoices', id }]
        }),
        deleteInvoice: builder.mutation<{ success: boolean }, string>({
            query: (id) => ({ url: `/invoices/${id}/`, method: 'DELETE' }),
            invalidatesTags: () => [{ type: 'Invoices', id: 'LIST' }],
        }),
    }),
});

export const { useGetInvoicesQuery, useGetInvoiceQuery, useAddInvoiceMutation, useUpdateInvoiceMutation, useDeleteInvoiceMutation } = invoiceAPI;