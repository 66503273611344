import React, { useEffect, useState } from "react";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { PaymentFilter, NewButton, Table, Title } from "../../components";
import { useGetPaymentsQuery } from "../../services/api/payment";

import { Payment } from "../../common/types/Payment";
import * as _ from "lodash";
import { ArchivePayment } from "../../common/types/ArchivePayment";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<Payment>();

const columns = [
  columnHelper.accessor("code", {
    header: "Code",
    id: "code",
  }),
  columnHelper.accessor((data: Payment) => data?.group?.name ?? "", {
    // Using nullish coalescing to ensure a string return type
    id: "group", // Necessary when using accessorFn to uniquely identify the column
    header: "MepGroup",
  }),
  columnHelper.accessor((data: Payment) => data?.payer?.name ?? "", {
    id: "payer",
    header: "Payer",
  }),
  columnHelper.accessor("real_amount", {
    header: "Amount €",
    id: "real_amount",
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    id: "amount",
  }),
  columnHelper.accessor("currency", {
    header: "Currency",
    id: "currency",
  }),
  columnHelper.accessor(
    (data: Payment | ArchivePayment) =>
      data.categories.map((c) => c.name).join(", "),
    {
      id: "categories",
      header: "Categories",
    }
  ),
  columnHelper.accessor("date", {
    header: "Date",
    id: "date",
  }),
  columnHelper.accessor("final_receivers", {
    header: "Final Receivers",
    id: "final_receivers",
  }),
];
let loadedFromUrl = true;

export function Payments() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState({});
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(
    queryPage !== null ? parseInt(queryPage) : 1
  );

  const data = useGetPaymentsQuery({ filter: filter, page: page });

  const navigate = useNavigate();
  let filterParams = new URLSearchParams(filter);

  const onLineClick = (row: Payment) => {
    navigate(`/payments/${row.id}`);
  };

  const newPayment = () => {
    navigate("/payments/new");
  };

  useEffect(() => {
    if (
      _.isEqual(filter, {}) &&
      !_.isEqual(filterParams, searchParams) &&
      loadedFromUrl &&
      searchParams.toString()
    ) {
      // @ts-ignore
      if (Number(searchParams.page) === 1) {
        setPage(Number(1));
        // @ts-ignore
        delete searchParams.page;
        // @ts-ignore
      } else if (Number(searchParams.page) > 1) {
        // @ts-ignore
        setPage(Number(searchParams.page));
      }

      setFilter(Object.fromEntries(searchParams));
    } else {
      let copy: object = filter;
      if (page > 1) {
        copy = { ...copy, page: Number(page) };
      }
      setSearchParams(copy as URLSearchParamsInit);
    }
    loadedFromUrl = false;
  }, [filter, page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Title className={"mb-3"}>Payments</Title>
      <PaymentFilter filter={filter} setFilter={setFilter}></PaymentFilter>

      {data.data ? (
        <Table
          data={data.data.results || []}
          count={data.data.count || 0}
          page={page}
          hasNext={data.data.next || null}
          hasPrev={data.data.previous || null}
          setPage={setPage}
          onLineClick={onLineClick}
          columns={columns}
        />
      ) : (
        <></>
      )}

      <div className="flex align-center justify-center mt-5">
        <NewButton onClick={newPayment}>New Payment</NewButton>
      </div>
    </>
  );
}
