import React, { useMemo, useState } from 'react';
import { Form, Subtitle } from '../../../components';
import { Field } from '../../../components/Form/Form';

import { useNavigate } from 'react-router-dom';

import { useGetAccountOwnersQuery, useMergeAccountOwnerMutation } from '../../../services/api/accountOwner';

import { useAppDispatch } from '../../../services/redux/hooks';
import { setNotification } from "../../../services/redux/actions/notification";


const defaultFields: Field[] = [
    {
        name: 'account_owners',
        type: 'select',
        label: 'Account Owners',
        multiple: true,
        required: true,
    },
];


export function MergeAccountOwners() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: accountOwners, isLoading } = useGetAccountOwnersQuery();
    const [formData, setFormData] = useState({});

    const [mergeAccountOwners, { error, isLoading: isEditLoading }] = useMergeAccountOwnerMutation();

    const onSubmit = async (data: any) => {
        setFormData(data);

        const resp = await mergeAccountOwners(data);

        if (!resp?.["error"]) {
            dispatch(setNotification({
                type: 'success',
                message: 'Account Owners merged'
            }));

            navigate("/account_owners");
        } else {
            dispatch(setNotification({
                type: 'error',
                message: resp?.["error"].data.error
            }));
        }
    }

    const selectFrom = useMemo(() => {
        return {
            account_owners: accountOwners?.map(accountOwner => ({
                value: accountOwner.id,
                label: accountOwner.name
            })),
        }
    }, [accountOwners]);

    return (
        <div>
            <Subtitle>Merge Account Owners</Subtitle>

            <div className="flex justify-center mb-8">
                <Form
                    fields={defaultFields}
                    selectFrom={selectFrom}
                    data={formData}
                    loading={isLoading || isEditLoading}
                    errors={error?.["data"]}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    );
}