import Select, {SingleValue} from "react-select";
import React, {useEffect, useState} from "react";
// @ts-ignore
import _ from "lodash";
import {Filter} from "../../../common/types/Filter";

export function CodeSelector(props: {
    filter: Filter;
    setFilter: Function;
    handleUpdate: Function;
    useQuery: Function
}) {
    const [searchValue, setSearchValue] = useState("")
    const codes = props.useQuery(searchValue)
    let [selectValue, setSelectValue] = useState({})

    useEffect(() => {
        if (props.filter.code) {
            let newSelectValue = {label: props.filter.code, value: props.filter.code}
            setSelectValue(newSelectValue)
            setSearchValue(props.filter.code)
        } else {
            setSearchValue("")
            setSelectValue([null])
        }
    }, [props.filter])

    const invokeLoad = (inputValue: string) => {
        setSearchValue(inputValue)
    }

    return (
        <>
            <Select
                isClearable={true}
                isLoading={codes.isLoading}
                inputValue={searchValue}
                placeholder={"Code..."}
                value={selectValue}
                menuPosition="fixed"
                options={codes.data && codes.data.results?.map(
                    (code: { code: object; }) => {
                        return {value: code.code, label: code.code}
                    }
                )}
                onChange={(e: SingleValue<any>) => {
                    props.handleUpdate(e, "code")
                    if (e?.value) {
                        setSelectValue({value: e.value, label: e.value})
                    } else {
                        setSelectValue([null])
                    }
                }}
                onInputChange={_.debounce(invokeLoad, 200)}
            />
        </>
    );

}

export default CodeSelector;
