import React, { useMemo } from 'react';
import { Form, Subtitle } from '../../../components';
import { Field } from '../../../components/Form/Form';

import { useNavigate, useParams } from 'react-router-dom';

import { useGetBankAccountQuery, useUpdateBankAccountMutation } from '../../../services/api/bankAccount';

import { useAppDispatch } from '../../../services/redux/hooks';
import { setNotification } from "../../../services/redux/actions/notification";
import { useGetAccountOwnersQuery } from '../../../services/api/accountOwner';

const defaultFields: Field[] = [
    {
        name: 'identifier',
        type: 'text',
        label: 'Identifier',
    },
    {
        name: 'bank_number',
        type: 'text',
        label: 'Bank number',
        required: true,
    },
    {
        name: 'iban',
        type: 'text',
        label: 'Iban',
        required: true,
    },
    {
        name: 'owner',
        type: 'select',
        label: 'Owner',
        required: true,
    }
];

export function EditBankAccount() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: bankAccount } = useGetBankAccountQuery(id!);
    const { data: accountOwners } = useGetAccountOwnersQuery();

    const [updateBankAccount, { error, isLoading }] = useUpdateBankAccountMutation();

    const onSubmit = async (data: any) => {
        data = { ...data, id };
        const resp = await updateBankAccount(data);

        if (!resp["error"]) {
            dispatch(setNotification({
                type: 'success',
                message: 'BankAccount modified'
            }));

            navigate("/account_owners/" + data.owner);
        }
    }

    const selectFrom = useMemo(() => {
        return {
            owner: accountOwners?.map(accountOwner => ({
                value: accountOwner.id,
                label: accountOwner.name
            })),
        }
    }, [accountOwners]);

    return (
        <>
            <Subtitle>Edit BankAccount {bankAccount?.identifier}</Subtitle>
            <div className="flex justify-center">
                <Form
                    data={bankAccount}
                    fields={defaultFields}
                    loading={isLoading}
                    errors={error?.["data"]}
                    selectFrom={selectFrom}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
}