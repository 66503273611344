import React from "react";
import { useParams } from "react-router-dom";

import { Presenter } from "../../../components";
import { useGetArchivePaymentQuery } from '../../../services/api/archivePayment';

import { Row } from '../../../components/Presenter/Presenter';
import { Payment } from "../../../common/types/Payment";


const rows: Row<Payment>[] = [
    {
        name: 'Code',
        accessor: data => data.code,
    },
    {
        name: 'Currency',
        accessor: data => data.currency,
    },
    {
        name: 'Amount',
        accessor: data => data.amount,
    },
    {
        name: 'Real amount',
        accessor: data => data.real_amount,
    },
    {
        name: 'Description',
        accessor: data => data.description,
    },
    {
        name: 'Date',
        accessor: data => data.date,
    },
    {
        name: 'Notes',
        accessor: data => data.notes,
    },
    {
        name: 'Balance',
        accessor: data => data?.balance || '',
    },
    {
        name: 'MepGroup',
        accessor: data => data.group,
    },
    {
        name: 'MEP',
        accessor: data => data?.mep || '',
    },
    {
        name: 'Payer',
        accessor: data => data.payer,
    },
    {
        name: 'Categories',
        accessor: data => data.categories.map(category => category.name).join(', '),
    },
    {
        name: 'Invoices',
        accessor: data => data.invoice.map(invoice => invoice.name).join(', '),
    },
    {
        name: 'Account owner',
        accessor: data => data.account_owner,
    },
    {
        name: 'Final receivers',
        accessor: data => data.final_receivers,
    },
    {
        name: 'Creator',
        accessor: data => data.creator,
    }
];



export function ArchivePayment() {
    const { id } = useParams();
    const { data } = useGetArchivePaymentQuery(id!);

    return (
        <>
            <h1 className="text-center text-lg mb-5 font-semibold text-gray-600">Payment</h1>

            <Presenter
                data={data}
                rows={rows}
            />
        </>
    );
}