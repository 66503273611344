import React, { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { NewButton, Table, Title } from "../../components";
import { useGetBankAccountsQuery } from "../../services/api/bankAccount";

import { BankAccount } from "../../common/types/BankAccount";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<BankAccount>(); // Initialize columnHelper for BankAccount type
const columns = [
  columnHelper.accessor("identifier", {
    header: "Identifier",
    id: "identifier",
    // Additional configuration like sorting, filtering can be added here
  }),
  columnHelper.accessor("bank_number", {
    header: "Bank number",
    id: "bank_number",
    // Additional configuration like sorting, filtering can be added here
  }),
  columnHelper.accessor("iban", {
    header: "IBAN",
    id: "iban",
    // Additional configuration like sorting, filtering can be added here
  }),
];

export function BankAccounts({ accountOwner }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(
    queryPage !== null ? parseInt(queryPage) : 1
  );

  const { data } = useGetBankAccountsQuery();
  const navigate = useNavigate();

  const onLineClick = (row: BankAccount) => {
    navigate(`/bank_accounts/${row.id}`);
  };

  const newBankAccount = () => {
    navigate("/bank_accounts/new", {
      state: {
        accountOwner,
      },
    });
  };

  const bankAccounts = useMemo(() => {
    return (
      data?.filter((bankAccount) => bankAccount.owner === accountOwner?.id) ||
      []
    );
  }, [data, accountOwner]);

  return (
    <>
      <Title>Bank Accounts</Title>

      <Table
        data={bankAccounts}
        count={data?.length || 0}
        page={page}
        setPage={setPage}
        onLineClick={onLineClick}
        columns={columns}
      />

      <div className="flex align-center justify-center mt-5">
        <NewButton onClick={newBankAccount}>New BankAccount</NewButton>
      </div>
    </>
  );
}
