import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';

import {authTokenMiddleware} from './middlewares/auth';

import authReducer from './reducers/auth';
import notificationReducer from './reducers/notification';
import loadingReducer from './reducers/loading';

import {checkAuth} from './actions/auth';

import {api} from '../api';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        notification: notificationReducer,
        loading: loadingReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        authTokenMiddleware,
        api.middleware,
    ),
});

setupListeners(store.dispatch);

store.dispatch(checkAuth());