import { Navigate, Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { useAppDispatch } from "../../services/redux/hooks";
import { login } from "../../services/redux/actions/auth";

const RequireAuth = () => {
  //const {keycloak} = useKeycloak()
  const dispatch = useAppDispatch();
  const { login: odicLogin, isAuthenticated } = useOidc();
  const { accessToken } = useOidcAccessToken();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(login(accessToken));
    } else {
      odicLogin();
    }
  }, [isAuthenticated, dispatch, accessToken, odicLogin]);

  return isAuthenticated ? <Outlet /> : <></>;
};

export default RequireAuth;
