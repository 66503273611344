import ClockLoader from "react-spinners/ClockLoader";

import { selectAPILoading, selectLoading } from "../../services/redux/selectors/loading";
import { useAppSelector } from "../../services/redux/hooks";

import "./Spinner.css";
import React from "react";

function Spinner() {
    const authLoading = useAppSelector(selectLoading);
    const apiLoading = useAppSelector(selectAPILoading);
    const loading = authLoading || apiLoading;

    if (!loading) return <></>;

    return (
        <div className="spinner-container">
            <ClockLoader
                color={"#000000"}
                loading={loading}
                size={150}
            />
        </div>
    );
}

export default Spinner;