import { fetchAPI } from "../../services/api";
import { AuthResponse } from "../../services/redux/types";

export const setAuthToken = (token?: string) => {
  if (token) {
    fetchAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete fetchAPI.defaults.headers.common["Authorization"];
  }
};

export const saveAuthStorage = (data?: AuthResponse) => {
  if (data) {
    localStorage.setItem("auth", JSON.stringify(data));
  } else {
    localStorage.removeItem("auth");
  }
};

export const getAuthLocalStorage = (): AuthResponse | null => {
  const data = localStorage.getItem("auth");
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

export const getTokenLocalStorage = (): string | null => {
  return getAuthLocalStorage()?.token;
};
