import React, { useMemo } from "react";

// export type Row<T> = {
//     name: string;
// } & ({
//     accessor: ((data: T) => string);
// } | {   
//     accessor: ((data: T) => string[]);
//     Component: () => JSX.Element;
// })

export interface Row<T> {
    name: string;
    accessor: ((data: T) => string | any);
    Component?: (data: any) => JSX.Element;
}

function Presenter<T>({ data, rows }: { data?: T, rows: Row<T>[] }) {
    const displayedRows = useMemo(() => {
        return rows.map(row => {
            return {
                name: row.name,
                value: data && row.accessor(data),
                Component: row?.Component,
            };
        });
    }, [data, rows]);

    return (
        <div className="relative content-center flex justify-center">
            <div className="flex flex-col width-5 w-full items-center">
                {data && displayedRows.map(row => (
                    <div key={row.name} className="grid grid-cols-2 gap-4 w-[90%] md:w-[50%]">
                        <div className="text-gray-600">{row.name}</div>
                        {row.Component ? <row.Component data={row.value} /> : <div className="text-gray-700 text-right">{row.value}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Presenter;