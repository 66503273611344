import React, { useState } from "react";

import { Table, Title } from "../../components";
import { ColumnFilter } from "../../components/Table/Filter";
import { useGetBalancesQuery } from "../../services/api/balance";

import { Mep } from "../../common/types/Mep";
import { useGetMepsQuery } from "../../services/api/mep";
import { Numbers } from "../../utils/misc";
import { useSearchParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MepBalance } from "../../common/types/Balance";

const columnHelper = createColumnHelper<MepBalance>();

const columns = [
  columnHelper.accessor("code", {
    header: "Code",
    filterFn: "auto",
    id: "code",
  }),
  columnHelper.accessor("balance_sum", {
    header: "Balance sum",
    filterFn: "auto",
    id: "balance_sum",
  }),
  columnHelper.accessor("balance_fix", {
    header: "Balance fix",
    filterFn: "auto",
    id: "balance_fix",
  }),
  columnHelper.accessor("balance_calc", {
    header: "Balance calc",
    filterFn: "auto",
    id: "balance_calc",
  }),
];
export function Balances() {
  let [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(
    queryPage !== null ? parseInt(queryPage) : 1
  );
  const [mepGroup, setMepGroup] = useState(1);
  const [start, setStart] = useState(new Date().getFullYear());
  const [end, setEnd] = useState(new Date().getFullYear());

  const { data: meps } = useGetMepsQuery();

  const { data } = useGetBalancesQuery({
    page,
    mep_group: mepGroup,
    start_year: start,
    end_year: end,
  });
  console.log(data);

  return (
    <>
      <Title>Balances</Title>

      <div className="flex justify-center">
        <div className="w-[100%] md:w-[30%] flex flex-row justify-evenly items-center mb-8 backdrop-blur-md font-bold text-s text-gray-700">
          <div className="flex flex-col">
            <label className="text-center mb-1">MEP group</label>
            <select
              onChange={(e) => setMepGroup(parseInt(e.target.value))}
              value={mepGroup}
              className="px-4 py-3 w-full shadow border rounded-md bg-white focus:border-gray-500 border-gray-400 text-sm focus:shadow-outline focus:outline-none "
            >
              {meps?.map((mep: Mep) => (
                <option className="text-sm" key={mep.name} value={mep.id}>
                  {mep.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-center mb-1">Start</label>
            <select
              onChange={(e) => setStart(parseInt(e.target.value))}
              value={start}
              className="px-4 py-3 w-full shadow border rounded-md bg-white focus:border-gray-500 border-gray-400 text-sm focus:shadow-outline focus:outline-none "
            >
              {Numbers(
                new Date().getFullYear() - 10,
                new Date().getFullYear()
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-center mb-1">End</label>
            <select
              onChange={(e) => setEnd(parseInt(e.target.value))}
              value={end}
              className="px-4 py-3 w-full shadow border rounded-md bg-white focus:border-gray-500 border-gray-400 text-sm focus:shadow-outline focus:outline-none "
            >
              {Numbers(
                new Date().getFullYear() - 10,
                new Date().getFullYear()
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <Table
        data={data ?? []}
        count={0}
        page={page}
        setPage={setPage}
        onLineClick={() => null}
        columns={columns}
      />
    </>
  );
}
