import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { logout } from "../../services/redux/actions/auth";
import { useAppDispatch, useAppSelector } from "../../services/redux/hooks";
import { selectUser } from "../../services/redux/selectors/auth";

//@ts-ignore
import Logo from "../../assets/images/logo.png";
//@ts-ignore
import Menu from "../../assets/images/menu.svg";
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";

import "./Navbar.css";

function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const { login, logout, isAuthenticated } = useOidc();
  const { accessTokenPayload } = useOidcAccessToken();

  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 pb-10">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <Link to="/" className="flex items-center">
          <img src={Logo} className="mr-3 h-6 sm:h-9" alt="Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap">
            GEA
          </span>
        </Link>
        <div className="flex md:order-2">
          {isAuthenticated && (
            <span className="text-base inline-flex mr-3 flex-col justify-center font-semibold text-gray-600">
              {accessTokenPayload?.preferred_username}
            </span>
          )}

          {isAuthenticated ? (
            <button
              className="text-white align-middle bg-blue-600 block hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
              onClick={() => {
                logout();
                localStorage.removeItem("auth");
                window.location.reload();
              }}
            >
              Logout
            </button>
          ) : (
            <button
              type="button"
              className="text-white align-middle bg-blue-600 block hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
              onClick={() => login()}
            >
              Login
            </button>
          )}

          <button
            onClick={() => setOpenMenu(!openMenu)}
            data-dropdown-toggle="mobile-menu-4"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="mobile-menu-4"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <img src={Menu} className="h-6 sm:h-9 text-white" alt="menu" />
          </button>
        </div>
        <div
          className={`${
            openMenu ? "h-full visible" : "h-0 invisible"
          } md:visible md:h-full justify-end items-center w-full md:flex md:w-auto md:order-1`}
          id="mobile-menu-4"
        >
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 text-sm font-medium">
            <li>
              <Link
                to="/home"
                className="block text-base py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                aria-current="page"
              >
                Home
              </Link>
            </li>

            {isAuthenticated && (
              <li>
                <Link
                  to="/payments"
                  className="block text-base py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                >
                  Payments
                </Link>
              </li>
            )}
            {isAuthenticated && (
              <li>
                <Link
                  to="/balances"
                  className="block text-base py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                >
                  Balances
                </Link>
              </li>
            )}
            {isAuthenticated && (
              <li>
                <Link
                  to="/invoices"
                  className="block text-base py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                >
                  Invoices
                </Link>
              </li>
            )}
            {isAuthenticated && (
              <li>
                <Link
                  to="/account_owners"
                  className="block text-base py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                >
                  Account Owners
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
