import React from "react"
export function Title({ children, className="" }) {
    let newClassName = "text-center text-lg bold font-bold " + className
    return (
        <h2 className={newClassName}>
            {children}
        </h2>
    );
}

export function Subtitle({ children }) {
    return (
        <h2 className="text-center text-lg mb-5 font-semibold text-gray-600">
            {children}
        </h2 >
    );
}