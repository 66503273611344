import React, {useEffect, useState} from "react";
import {Filter} from "../../../common/types/Filter";
import {useGetMepsQuery} from "../../../services/api/mep";
import Select from "react-select";

export function MepSelector(props: {
    filter: Filter;
    setFilter: Function;
    handleUpdate: Function;
}) {
    const {data: meps, isLoading} = useGetMepsQuery()
    const [selectedValue, setSelectedValue] = useState({})
    useEffect(() => {
        if (props.filter.mep && !isLoading) {
            let newValue = {value: props.filter.mep, label: props.filter.mep}
            setSelectedValue(newValue)
        } else {
            setSelectedValue([null])
        }

    }, [props.filter, isLoading])
    return (
        <>
            <Select
                isClearable={true}
                placeholder={"Mep"}
                value={selectedValue}
                menuPosition="fixed"
                onChange={(e) => {
                    props.handleUpdate(e, "mep")
                }}
                options={meps?.map(
                    (mep) => {
                        return {value: mep.name, label: mep.name}
                    }
                )
                }/>
        </>
    )
}