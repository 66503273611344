import React, { useMemo, useState, useEffect } from "react";
import Select from 'react-select';

export type InputType = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';

export type Field = {
    name: string,
    label: string,
    value?: string | number,
    accessor?: (arg: any) => string[] | string | number[] | number,
    required?: boolean,
    default?: string | number,
} & ({
    type: InputType
} | {
    multiple?: boolean,
    type: 'select',
})

export type selectFrom = {
    [key: string]: {
        value?: string | number,
        label?: string
    }[] | undefined
}

export type Errors = {
    [key: string]: string
}

function Form<T>({ fields, data, selectFrom, errors, loading, onSubmit }: { fields: Field[], data?: T, selectFrom?: selectFrom, errors?: Errors, loading: boolean, onSubmit: Function }) {
    const memoDefaultState = useMemo(() => {
        let defaultState = {};
        fields.forEach(field => {
            if (field.type === 'select') {
                if (field.multiple) {
                    if (data && data[field.name] && selectFrom && selectFrom[field.name]) {
                        let datas = field.accessor ? field.accessor(data[field.name]) : data[field.name];
                        if (Array.isArray(datas)) {
                            defaultState[field.name] = datas.map(d => selectFrom[field.name]?.find(v => v.label === d || v.value === d)) ?? '';
                        } else {
                            defaultState[field.name] = '';
                        }
                    } else {
                        defaultState[field.name] = '';
                    }
                } else {
                    if (data && data[field.name] && selectFrom && selectFrom[field.name]) {
                        let datas = field.accessor ? field.accessor(data[field.name]) : data[field.name];
                        defaultState[field.name] = selectFrom[field.name]?.find(v => v.label === datas || v.value === datas) ?? '';
                    } else {
                        defaultState[field.name] = '';
                    }
                }
            } else {
                if (data) {
                    if (field.accessor) {
                        defaultState[field.name] = field.accessor(data[field.name]) ?? '';
                    } else {
                        defaultState[field.name] = data[field.name] ? data[field.name] : '';
                    }
                } else {
                    defaultState[field.name] = '';
                }
            }

            if (defaultState[field.name] === undefined || defaultState[field.name] === []) {
                defaultState[field.name] = '';
            }
        });

        return defaultState;
    }, [data, fields, selectFrom]);

    const [formData, setFormData] = useState(memoDefaultState);

    useEffect(() => {
        setFormData(memoDefaultState);
    }, [memoDefaultState, loading]);

    const onChange = useMemo(() => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    }, [setFormData]);

    const onFileChange = useMemo(() => {
        return (e: React.ChangeEvent<any>) => {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.files?.[0],
            }));
        }
    }, [setFormData]);

    const onSelectChange = useMemo(() => {
        return (data: { value: string, label: string }, actionMeta: any) => {
            setFormData((prevState) => ({
                ...prevState,
                [actionMeta.name]: data,
            }));
        }
    }, [setFormData]);

    const onClick = useMemo(() => {
        return () => {
            let data = formData;
            fields.forEach(field => {
                if (field.type === 'select') {
                    if (data[field.name]) {
                        if (field.multiple && Array.isArray(data[field.name])) {
                            data[field.name] = data[field.name]?.map(d => d?.value);
                        } else if (data[field.name].value) {
                            data[field.name] = data[field.name]?.value;
                        }
                    }
                }

                if (data[field.name] === "") {
                    data[field.name] = fields[field.name]?.default ?? null;
                }
            });
            onSubmit(data);
        }
    }, [formData, fields, onSubmit]);

    if (loading) {
        return <></>;
    }

    // TODO: check for uncontroller input change
    return (
        <div className="w-[90%] md:w-[50%]">
            {fields.map((field, i) =>
                <div className="relative w-full mb-5 group" key={`field-${i}`}>
                    {
                        field.type === 'select' ?
                            <>
                                {(selectFrom && selectFrom[field.name]) && formData &&
                                    <>
                                        <div className="mt-11"></div>
                                        <Select
                                            isMulti={field.multiple}
                                            options={selectFrom[field.name]}
                                            name={field.name}
                                            key={`select-${field.name}`}
                                            value={formData[field.name] || ''}
                                            onChange={onSelectChange}
                                            isClearable={!field.required}
                                        />
                                        <label htmlFor={field.name} key={`label-${field.name}`} className="peer-focus:font-medium absolute text-m text-gray-500 duration-300 transform -translate-y-6 scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{field.label}</label>
                                    </>
                                }
                            </>
                            :
                            field.type === 'file' ?
                                <div className="mt-3">
                                    <label htmlFor={field.name} key={`label-${field.name}`} className="peer-focus:font-medium absolute text-m text-gray-500 duration-300 transform -translate-y-6 scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{field.label}</label>
                                    <span className="sr-only">Choose File</span>
                                    <input type="file" name={field.name} onChange={onFileChange} required={field.required} className="block w-full text-sm text-gray-500 rounded-xl file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" />
                                </div>
                                :
                                <>
                                    <input type={field.type} name={field.name} key={`input-${field.name}`} className="block py-2 px-0 w-full text-m text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " value={formData[field.name] || ''} onChange={onChange} required={field.required} />
                                    <label htmlFor={field.name} key={`label-${field.name}`} className="peer-focus:font-medium absolute text-m text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{field.label}</label>
                                </>
                    }

                    {
                        errors && errors[field.name] &&
                        <p className="text-red-500 text-sm pt-1 italic">{errors[field.name]}</p>
                    }
                </div>

            )
            }

            <button onClick={onClick} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
        </div >
    );
}

export default Form;