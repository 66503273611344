import React, { MouseEventHandler } from "react";

function NewButton({ onClick, children }: { onClick: MouseEventHandler<HTMLButtonElement>, children: string }) {
    return (
        <div className="flex align-center justify-center mt-5">
            <button onClick={onClick} className="bg-transparent hover:bg-blue-700 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded flex flex-row items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                {children}
            </button>
        </div>
    );
}

export default NewButton;