import React, { useState } from 'react';
import { Form, Subtitle } from '../../../components';
import { Field } from '../../../components/Form/Form';

import { useNavigate } from 'react-router-dom';

import { useAddInvoiceMutation } from '../../../services/api/invoice';

import { useAppDispatch } from '../../../services/redux/hooks';
import { setNotification } from "../../../services/redux/actions/notification";

const defaultFields: Field[] = [
    {
        name: 'file',
        type: 'file',
        label: 'File',
        required: true,
    },
    {
        name: 'name',
        type: 'text',
        label: 'Name',
        required: true,
    },
];

export function NewInvoice() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [newInvoice, { error, isLoading }] = useAddInvoiceMutation();
    const [formData, setFormData] = useState({});


    const onSubmit = async (data: any) => {
        setFormData(data);
        const { file, name } = data;

        const body = new FormData();
        body.append('file', file);
        body.append('name', name);

        const resp = await newInvoice(body);

        if (!resp["error"]) {
            dispatch(setNotification({
                type: 'success',
                message: 'Invoice created'
            }));

            navigate("/invoices");
        }
    }

    return (
        <>
            <Subtitle>New Invoice</Subtitle>
            <div className="flex justify-center">
                <Form
                    data={formData}
                    fields={defaultFields}
                    loading={isLoading}
                    // @ts-ignore
                    errors={error?.data}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
}