import React from "react";
import { Invoice as InvoiceI } from "../../../common/types/Invoice";

export function Invoice({ data }: { data: InvoiceI[] }) {
    return (
        <div className="text-right text-blue-700">
            {data.map(invoice => (
                <a key={invoice.name} href={invoice.file}>
                    {invoice.name}
                </a>
            ))}
        </div>
    )
}