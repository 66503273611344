import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { NewButton, Table, Title } from "../../components";
import { ColumnFilter } from "../../components/Table/Filter";
import { useGetAccountOwnersQuery } from "../../services/api/accountOwner";

import { AccountOwner } from "../../common/types/AccountOwner";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<AccountOwner>();
const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    filterFn: "auto",
    id: "name",
  }),
  columnHelper.accessor(
    (data: AccountOwner) => {
      const payments =
        data.payments?.length > 10
          ? [...data.payments.slice(0, 10), "..."]
          : data?.payments;
      return payments?.map((payment) => payment?.code).join(", ");
    },
    {
      id: "payments",
      header: "Payments",
      filterFn: "auto",
    }
  ),
];

export function AccountOwners() {
  const [page, setPage] = useState(1);

  const { data } = useGetAccountOwnersQuery(page);
  const navigate = useNavigate();

  const nextPage = () => setPage((page) => page + 1);
  const prevPage = () => setPage((page) => (page > 1 ? page - 1 : page));

  const onLineClick = (row: AccountOwner) => {
    navigate(`/account_owners/${row.id}`);
  };

  const newAccountOwner = () => {
    navigate("/account_owners/new");
  };

  const mergeAccounts = () => {
    navigate("/account_owners/merge");
  };

  return (
    <>
      <Title>Account Owners</Title>

      <Table
        data={data || []}
        count={data?.length || 0}
        page={page}
        nextPage={nextPage}
        prevPage={prevPage}
        onLineClick={onLineClick}
        columns={columns}
      />

      <div className="flex flex-row align-center justify-center mt-5">
        <NewButton onClick={newAccountOwner}>New AccountOwner</NewButton>

        <div className="flex flex-row align-center justify-center mt-5 ml-5">
          <button
            onClick={mergeAccounts}
            className="bg-transparent hover:bg-blue-700 text-blue-700 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded flex flex-row items-center"
          >
            Merge Accounts
          </button>
        </div>
      </div>
    </>
  );
}
