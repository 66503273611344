import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ArchivePayment } from "../../common/types/ArchivePayment";

import { ArchiveFilter, Table, Title } from "../../components";
import { useGetArchivePaymentsQuery } from "../../services/api/archivePayment";

import { createColumnHelper } from "@tanstack/react-table"; // Import based on the new React Table version

const columnHelper = createColumnHelper<ArchivePayment>(); // Assuming createColumnHelper is imported and initialized

const columns = [
  columnHelper.accessor("code", {
    header: "Code",
    id: "code",
  }),
  columnHelper.accessor("group", {
    header: "MepGroup",
    id: "group",
  }),
  // For columns with custom render or accessor functions
  columnHelper.accessor("payer", {
    header: "Payer",
    id: "payer",
  }),
  columnHelper.accessor((row) => `${row.real_amount} EUR`, {
    id: "bank_transfer", // Provide an ID for columns with accessorFn
    header: "Bank transfer",
  }),
  columnHelper.accessor((row) => `${row.amount} ${row.currency}`, {
    id: "amount_with_currency",
    header: "Amount",
  }),
  columnHelper.accessor((row) => row.categories.map((c) => c.name).join(", "), {
    id: "categories",
    header: "Categories",
  }),
  columnHelper.accessor("date", {
    header: "Date",
    id: "date",
  }),
  columnHelper.accessor("final_receivers", {
    header: "Final Receivers",
    id: "final_receivers",
  }),
];

let loadedFromUrl = true;

export function ArchivePayments() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState({});
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(
    queryPage !== null ? parseInt(queryPage) : 1
  );

  const data = useGetArchivePaymentsQuery({ filter: filter, page: page });

  const navigate = useNavigate();
  let filterParams = new URLSearchParams(filter);

  useEffect(() => {
    if (
      _.isEqual(filter, {}) &&
      !_.isEqual(filterParams, searchParams) &&
      loadedFromUrl &&
      searchParams.toString()
    ) {
      // @ts-ignore
      if (Number(searchParams.page) === 1) {
        setPage(Number(1));
        // @ts-ignore
        delete searchParams.page;
        // @ts-ignore
      } else if (Number(searchParams.page) > 1) {
        // @ts-ignore
        setPage(Number(searchParams.page));
      }

      setFilter(Object.fromEntries(searchParams));
    } else {
      let copy: object = filter;
      if (page > 1) {
        copy = { ...copy, page: Number(page) };
      }
      setSearchParams(copy as URLSearchParamsInit);
    }
    loadedFromUrl = false;
  }, [filter, page]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLineClick = (row: ArchivePayment) => {
    navigate(`/archived/${row.id}`);
  };

  return (
    <>
      <Title className={"mb-3"}>Filter</Title>
      <ArchiveFilter filter={filter} setFilter={setFilter}></ArchiveFilter>
      <Title className={"my-4"}>Archived Payments</Title>
      {data.data ? (
        <Table
          data={data.data.results || []}
          count={data.data.count || 0}
          page={page}
          hasNext={data.data.next || null}
          hasPrev={data.data.previous || null}
          setPage={setPage}
          onLineClick={onLineClick}
          columns={columns}
        />
      ) : (
        <></>
      )}
    </>
  );
}
