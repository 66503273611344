import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthData } from "../../auth/types";
import authAPI from "../../auth";

export const login = createAsyncThunk(
    'auth/login',
    async (token: string) => {
        return await authAPI.login(token);
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        return await authAPI.logout();
    }
);

export const checkAuth = createAsyncThunk(
    'auth/checkAuth',
    async () => {
        return await authAPI.checkAuth();
    }
);