import { api } from '..';
import { Mep } from '../../../common/types/Mep';
import { PaginatedResponse } from '../types';

export const mepAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getMeps: builder.query<Mep[], void>({
            query: () => process.env.REACT_APP_MEPS_URL,
            transformResponse: (response: PaginatedResponse<Mep>, meta, arg) => response.results,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ code }) => ({ type: 'Meps' as const, id: code })),
                        { type: 'Meps', id: 'LIST' },
                    ]
                    : [{ type: 'Meps', id: 'LIST' }],
        }),
    }),
});

export const { useGetMepsQuery } = mepAPI;