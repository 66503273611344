import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./services/redux/store";
import { ConfirmModalProvider } from "./services/context/modals/ConfirmModal";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import keycloak from "./utils/keycloak";
import { OidcProvider } from "@axa-fr/react-oidc";

const configuration = {
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID || "administration-test",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri:
      window.location.origin + "/authentication/silent-callback",
  scope: "openid email offline_access", // offline_access scope allow your client to retrieve the refresh_token
  authority:
      process.env.REACT_APP_AUTH_REALMS ||
      "http://localhost:8080/auth/realms/master",
  service_worker_only: false,
  demonstrating_proof_of_possession: false, // demonstrating proof of possession will work only if access_token is accessible from the client (This is because WebCrypto API is not available inside a Service Worker)
};

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <OidcProvider configuration={configuration}>
    <Provider store={store}>
      <React.StrictMode>
        <ConfirmModalProvider>
          <App />
        </ConfirmModalProvider>
      </React.StrictMode>
    </Provider>
  </OidcProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
