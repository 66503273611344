import {Store} from "../types";

export const selectLoading = (state: Store) => state.loading.loading;

const selectAPIkeys = ['ArchivePayments']
export const selectAPILoading = (state: Store) => {
    return Object.keys(state.api.queries).filter(key => selectAPIkeys.includes(key)).some(
        (query: any) => query?.status === 'pending'
    ) || Object.keys(state.api.mutations).some(
        (query: any) => query?.status === 'pending');
}