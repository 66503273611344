import React, { useEffect, useMemo, useState } from 'react';
import { Form, Subtitle } from '../../../components';
import { Field } from '../../../components/Form/Form';

import { useLocation, useNavigate } from 'react-router-dom';

import { useAddBankAccountMutation } from '../../../services/api/bankAccount';

import { useAppDispatch } from '../../../services/redux/hooks';
import { setNotification } from "../../../services/redux/actions/notification";
import { useGetAccountOwnersQuery } from '../../../services/api/accountOwner';

const defaultFields: Field[] = [
    {
        name: 'identifier',
        type: 'text',
        label: 'Identifier',
    },
    {
        name: 'bank_number',
        type: 'text',
        label: 'Bank number',
        required: true,
    },
    {
        name: 'iban',
        type: 'text',
        label: 'Iban',
        required: true,
    },
    {
        name: 'owner',
        type: 'select',
        label: 'Owner',
        required: true,
    }
];

export function NewBankAccount() {
    const { state }: { state: any } = useLocation();
    const { accountOwner } = state;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [newBankAccount, { error, isLoading }] = useAddBankAccountMutation();
    const { data: accountOwners } = useGetAccountOwnersQuery();
    const [formData, setFormData] = useState({});


    const onSubmit = async (data: any) => {
        setFormData(data);
        const resp = await newBankAccount(data);

        if (!resp["error"]) {
            dispatch(setNotification({
                type: 'success',
                message: 'BankAccount created'
            }));

            navigate("/account_owners/" + accountOwner.id);
        }
    }

    const selectFrom = useMemo(() => {
        return {
            owner: accountOwners?.map(accountOwner => ({
                value: accountOwner.id,
                label: accountOwner.name
            })),
        }
    }, [accountOwners]);

    useEffect(() => {
        if (accountOwner) {
            setFormData({
                owner: accountOwner.id
            });
        }
    }, [accountOwner]);


    return (
        <>
            <Subtitle>New BankAccount</Subtitle>
            <div className="flex justify-center">
                <Form
                    data={formData}
                    fields={defaultFields}
                    loading={isLoading}
                    // @ts-ignore
                    errors={error?.data}
                    selectFrom={selectFrom}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
}