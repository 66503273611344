import { createSlice } from '@reduxjs/toolkit';

import { NotificationState } from '../types';
import { login, logout } from '../actions/auth';

const initialState: NotificationState = {
    message: undefined,
    type: undefined,
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        clearNotification: (state) => {
            state.message = undefined;
            state.type = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(logout.fulfilled, (state, action) => {
            state.message = action.payload?.message || 'Logout successful';
            state.type = 'success';
        });

        builder.addCase(login.rejected, (state, action) => {
            state.message = action.error?.message || 'Login failed';
            state.type = 'error';
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.message = action.error?.message || 'Logout failed';
            state.type = 'error';
        });
    }
});

export default notificationSlice.reducer;